import React from 'react';
import ReactDOM from 'react-dom';

import CopyJob from '../../../job/copy/CopyJob';
import { EmailTags } from '@vectorworks/vcs-ui/dist/lib/Input/Tags/EmailTags';
import { Sidebar } from '../../../Sidebar/Main/Sidebar';
import TrashCategorySelect from '../../../Components/TrashCategorySelect';

const COMPONENTS = {
    CopyJob,
    EmailTags,
    Sidebar,
    TrashCategorySelect
};

ko.bindingHandlers.react = {
    init () {
        return { controlsDescendantBindings: true };
    },
    update (element, valueAccessor) {
        const { component, props } = valueAccessor();
        ReactDOM.render(React.createElement(COMPONENTS[component], props), element);
    }
};
