import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import PropTypes from 'prop-types';

import Slider from 'react-slick';

import { request } from '../base/axios';

const closeTour = () => {
    unmountComponentAtNode(document.getElementById('welcome-tour'));
    request({
        url: `${Settings.API_V1_ROOT}welcome_tour/`,
        method: 'POST'
    });
    // call api to set userpref
};

const TourHeader = () =>
    <div className='wt-header'>
        <span className='hover-icon icon-md icon-close' onClick={closeTour} />
    </div>;

const PageTemplate = ({ image, title, children }) =>
    <div className='wt-page'>
        <img className='wt-image' src={Settings.ASSETS.images[image]} />
        <div className='wt-text-content'>
            <div className='wt-title'>{title}</div>
            <div className='wt-descr'>
                {children}
            </div>
        </div>
    </div>;
PageTemplate.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string
};

const ProductSuite = () =>
    <PageTemplate title={gettext('Product suite')} image='wt-product-suite.png'>
        { gettext('Vectorworks Cloud Services comprises a set of applications to make your workflows more flexible and efficient:') }
        <li style={{ marginTop: 8 }}>{gettext('Vectorworks Cloud Services desktop app')}</li>
        <li>{gettext('Cloud menu in Vectorworks')}</li>
        <li>{gettext('Web portal')}</li>
        <li>{gettext('Vectorworks Nomad mobile app')}</li>
    </PageTemplate>;
ProductSuite.displayName = gettext('Product suite');

const AccessAnywhere = () =>
    <PageTemplate title={gettext('Access anywhere')} image='wt-access-anywhere.png'>
        { gettext('Synchronize and store your documents on the cloud to access your designs from any web-enabled device.') }
    </PageTemplate>;
AccessAnywhere.displayName = gettext('Access anywhere');

const CloudComputing = () =>
    <PageTemplate title={gettext('Cloud computing')} image='wt-processing.png'>
        { gettext('Shift resource-heavy processing, such as rendering viewports and panoramic images, to the cloud, and continue design work on the local computer.') }
    </PageTemplate>;
CloudComputing.displayName = gettext('Cloud computing');

const InteractivePresentations = () =>
    <PageTemplate title={gettext('Interactive presentations')} image='wt-presentations.png'>
        { gettext('Create virtual, immersive, 360° walkthroughs of your design by linking panoramic images to each other. Or enhance 2D images or PDFs with other content, such as text or other linked files.') }
    </PageTemplate>;
InteractivePresentations.displayName = gettext('Interactive presentations');

const SharingAndCollaboration = () =>
    <PageTemplate title={gettext('Sharing and collaboration')} image='wt-sharing.png'>
        { gettext('Share PDFs, interactive 3D models, panoramic images and interactive presentations with clients and collaborators.') }
    </PageTemplate>;
SharingAndCollaboration.displayName = gettext('Sharing and collaboration');

const Integrations = () =>
    <PageTemplate title={gettext('Integrations')} image='wt-integrations.png'>
        { gettext('Use all of the file processing, sharing and collaboration features of Vectorworks Cloud Services with files stored on Dropbox or Google Drive.') }
        <br />
        <br />
        { gettext('Add PDF files from Vectorworks Cloud Services to Bluebeam Studio Sessions and invite participants to collaborate. When finished, pull the marked up files for easy storage and file management.') }
    </PageTemplate>;
Integrations.displayName = gettext('Integrations');

const Pages = [
    ProductSuite,
    AccessAnywhere,
    CloudComputing,
    InteractivePresentations,
    SharingAndCollaboration,
    Integrations
];

function Next (props) {
    return props.slideCount - 1 > props.currentSlide
        ? <span
            className='icon icon-md icon-nav icon-right-arrow'
            onClick={props.onClick}
        />
        : null;
}
Next.propTypes = {
    onClick: PropTypes.func,
    currentSlide: PropTypes.number,
    slideCount: PropTypes.number
};

function Prev (props) {
    return props.currentSlide !== 0
        ? <span
            className='icon icon-md icon-nav icon-left-arrow'
            onClick={props.onClick}
        />
        : null;
}
Prev.propTypes = {
    onClick: PropTypes.func,
    currentSlide: PropTypes.number
};

class WelcomeTour extends React.Component {
    state = {
        activePage: 0
    };

    next = () => {
        if (this.state.activePage < Pages.length - 1) {
            this.setState(({ activePage }) => ({ activePage: activePage + 1 }));
        };
    };

    prev = () => {
        if (this.state.activePage > 0) {
            this.setState(({ activePage }) => ({ activePage: activePage - 1 }));
        };
    };

    handleSidebarClick = (page) => () => {
        this.setState({ activePage: Pages.indexOf(page) });
    };

    isSelected = (page) => {
        return this.state.activePage === Pages.indexOf(page);
    };

    isLastPage = () => {
        return this.state.activePage === Pages.length - 1;
    };

    renderSlick = () => {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <Next action={this.next} />,
            prevArrow: <Prev action={this.prev} />,
            initialSlide: this.state.activePage
        };

        return (
            <Slider {...settings}>
                { Pages.map(PageComponent => <PageComponent key={PageComponent.displayName} />) }
            </Slider>
        );
    };

    render () {
        const PageComponent = Pages[this.state.activePage];
        return (
            <div className='dialog__root'>
                <div className='dialog__overlay'>

                    <div className='dialog-lg' style={{ height: 470 }}>
                        <div className='wt-contents'>

                            <div className='hidden-xs wt-sidepanel'>
                                {Pages.map(page =>
                                    <a
                                        key={page.displayName}
                                        className={'sidebar-link ' + (this.isSelected(page) ? 'item-selected' : '')}
                                        onClick={this.handleSidebarClick(page)}
                                    ><span>{page.displayName}</span>
                                    </a>
                                )}

                                {
                                    this.isLastPage()
                                        ? <div
                                            className='btn-next vw-btn-round vw-btn-sm vw-btn-prim'
                                            onClick={closeTour}
                                        >{gettext('Done')}</div>
                                        : <div
                                            className='btn-next vw-btn-round vw-btn-sm vw-btn-prim'
                                            onClick={this.next}
                                        >{gettext('Next')}</div>
                                }
                            </div>

                            <div className='wt-layout'>
                                <TourHeader />

                                <div className='hidden-xs'>
                                    <PageComponent />
                                </div>
                                { this.renderSlick() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WelcomeTour;
